/* variables */
//colors
$color_bg: #ffffff;
$color_text: #000000;
$color_link: #4625eb;
$color_btn: #4625eb;
$color_red: #F70872;

//fonts
$font_size: 1.6rem;
$font_regular: 'IBM Plex Mono', Arial, sans-serif;
$font_header: 'Bebas Neue', Arial, sans-serif;

$media: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1920px
);
