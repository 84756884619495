.ac {
  counter-increment: item;
  border-top: 1px solid rgba(70, 37, 235, 0.3);
  box-sizing: border-box;
  &:nth-child(2){
    .faqListItem{
      margin-bottom: 1rem;
      @include screen(lg) {
        margin-bottom: 0;
      }
    }
  }
}
.ac:last-child{
  border-bottom: 1px solid rgba(70, 37, 235, 0.3);
}
.ac .ac-header {
  margin: 0;
  padding: 0;
}
.ac .ac-trigger {
  font-family: $font_regular;
 font-size: 1.8rem;
  color: #000;
  font-weight: 500;
  text-align: left;
  width: 100%;
  padding: 2rem 5.5rem 2rem 4rem;
  display: block;
  cursor: pointer;
  background-color: transparent;
  transition: color 0.25s ease;
  position: relative;
  text-decoration: none;
  margin: 0;
  border: 0;
  @include screen(lg) {
    font-size: 3rem;  
    padding: 2rem 2rem 2rem 20rem;
  }
  &:before{
    content: counter(item, decimal-leading-zero);
    color: $color_link;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.ac .ac-trigger::after {
  content: "";
  text-align: center;
  width: 3.5rem;
  height: 3.5rem;
  transform: rotate(0deg) translateY(-50%);
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transition: all 0.4s ease-in-out;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M47.375 45.1278L47.375 1.86073L44.7528 1.86073L44.7528 43.3112L2.06656 0.624998L0.212635 2.47958L42.8608 45.1278L1.48573 45.1278L1.48573 47.75L44.7528 47.75L47.375 47.75L47.375 45.1278Z' fill='url(%23paint0_linear_1539_3164)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1539_3164' x1='43.3552' y1='11.9408' x2='3.3569' y2='38.5953' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23F70872'/%3E%3Cstop offset='0.229982' stop-color='%23CB118F'/%3E%3Cstop offset='0.401042' stop-color='%23A017AF'/%3E%3Cstop offset='0.578125' stop-color='%238E18BA'/%3E%3Cstop offset='0.733233' stop-color='%235C20DD'/%3E%3Cstop offset='1' stop-color='%233727F3'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  @include screen(lg) {
    width: 4.7rem;
  height: 4.7rem;
  }
}
.faqHiddenConteiner{
  padding: 0 2rem 2rem 4rem;
  max-width: 1120px;
  @include screen(lg) {
    padding: 0 2rem 2rem 20rem;
  }
}
.faqListItem{
  font-size: 1.6rem;
  padding-left: 22px;
  margin-bottom: 1rem;
  @include screen(lg) {
    margin-bottom: 2rem;
    font-size: 2.4rem;  
    padding-left: 27px;
  }
  &::before{
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.472012 5.21413L0.40982 5.28198C0.184328 5.52797 0.203404 5.91082 0.452232 6.13317L3.22645 8.61225C3.45926 8.82029 3.81284 8.81456 4.03878 8.59908L11.5917 1.39587C11.821 1.17715 11.8402 0.817614 11.6355 0.575685L11.5556 0.48119C11.3341 0.219518 10.9387 0.196746 10.6887 0.431272L4.01852 6.68884C3.80073 6.89316 3.46568 6.90579 3.23312 6.71845L1.28913 5.15244C1.04312 4.95426 0.685477 4.98127 0.472012 5.21413Z' fill='%234625EB'/%3E%3C/svg%3E%0A");  
    width: 12px;
    height: 8px;
    @include screen(lg) {
      width: 17px;
      height: 12px;
      top: 8px;
    }
  }
  strong{
    display: block;
    margin-bottom: 0;
    @include screen(lg) {
      margin-bottom: 1rem;
    }
  }
}
.ac .ac-panel {
  overflow: hidden;
  transition-property: height, visibility;
  transition-timing-function: ease;
}
.ac .ac-panel .ac-text {
  font: 15px/24px "Arial", sans-serif;
  color: #111;
  padding: 10px;
  margin: 0;
}
.ac.js-enabled .ac-panel {
  visibility: hidden;
}
.ac.is-active .ac-panel {
  visibility: visible;
}
.ac.is-active > .ac-header .ac-trigger::after {
  transform: rotate(-90deg) translateY(-50%);
  transform-origin: top center;
  transition: all 0.4s ease-in-out;
}
.faqListItemDisklamer{
  font-size: 1.2rem;
  padding-left: 22px;
  @include screen(lg) {
    font-size: 1.8rem;
    padding-left: 27px;
  }
}
.faq{
  padding: 5rem 0 10rem;
  @include screen(xxxl) {
      padding: 10rem 0 15rem;
    }
}