header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  .flex-between {
    align-items: flex-end;
    overflow: hidden;
    padding: 2rem 0;
    position: relative;
    @include screen(lg) {
      padding: 3rem 0 0;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      background: linear-gradient(146.34deg, #f70872 14.71%, #cb118f 31.62%, #a017af 44.2%, #8e18ba 57.22%, #5c20dd 68.62%, #3727f3 88.23%);
      bottom: 0;
      height: 2px;
      width: 100%;
      max-width: 720px;
      right: 0%;
      animation-name: menuAnimate;
      animation-duration: 2s;
    }
  }
  .logo {
    width: 5.7rem;
    height: auto;
    z-index: 2000;
    @include screen(lg) {
      width: 13.9rem;
    }
  }
  .nav__list {
    display: flex;
    flex-direction: column;
    @include screen(lg) {
      justify-content: flex-end;
      flex-direction: row;
    }
    li {
      padding: 1rem 0;
      @include screen(lg) {
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        padding-right: 5rem;
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  .nav__item a {
    font-size: 2.2rem;
    font-weight: 500;
    position: relative;
    display: inline-block;
    color: $color_text;
    cursor: pointer;
    letter-spacing: -0.002rem;
    text-transform: uppercase;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: attr(data-text);
      opacity: 0;
      font-weight: bold;
      transition: opacity 0.2s linear;
    }
    &:active,
    &:hover {
      color: $color_link;
      &::before {
        opacity: 1;
      }
    }
  }

  &.mob-menu-show {
    .nav-toggle-btn {
      &:before {
        transform: rotate3d(0, 0, 1, 405deg);
        transition: 0.6s;
        margin: 0;
        position: relative;
        top: 2px;
      }
      &:after {
        transform: rotate3d(0, 0, 1, 495deg);
        transition: 0.6s;
        margin: 0;
        position: relative;
        top: 0;
      }
    }
    .nav-holder {
      opacity: 1 !important;
      right: 0 !important;
    }
  }
  .nav-holder {
    text-align: center;
    display: block;
    position: fixed;
    top: 0;
    left: auto;
    bottom: 0;
    right: -110%;
    width: 100%;
    height: 100%;
    z-index: 1000;
    box-sizing: border-box;
    padding: 7rem 1rem 0;
    background: #fff;
    opacity: 0;
    overflow: auto;
    transition: right 0.4s ease, opacity 0.4s ease;
    @include screen(lg) {
      width: 100%;
      position: initial;
      padding: 0;
      opacity: 1;
      background: none;
    }
  }
  .nav-toggle-btn {
    z-index: 2000;
    cursor: pointer;
    outline: 0;
    background: none;
    border: none;
    position: absolute;
    width: 31px;
    height: 31px;
    padding: 0;
    left: auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    @include screen(lg) {
      display: none;
    }
    &:before,
    &:after {
      content: '';
      display: block;
      background: #000;
      width: 31px;
      border-radius: 2px;
      height: 2px;
      margin: 0 0 7px;
    }
    &:after {
      margin: 0;
    }
  }
}
.prevent-scroll {
  height: 100%;
  overflow: hidden;
}
