.disscussForm{
    padding: 6rem 0 12rem;
    background: linear-gradient(100.84deg, #5120DB 0%, #42A3FC 100%);
    @include screen(xxxl) {
        padding: 9rem 0 10rem;
    }
    .formWrapper{
        max-width: 1130px;
        margin: 0 auto;
    }
    h3{
        color: #fff;
        font-family: $font_regular;
        font-size: 3.2rem;
        margin-bottom: 2rem;
        @include screen(lg) {
            font-size: 4.5rem;
        }
        @include screen(xxxl) {
            font-size: 5.5rem;
        }
    }
    .disscussTextWrapper{
        display: flex;
        flex-direction: column;
        margin-bottom: 6rem;
        @include screen(lg) {
            align-items: flex-end;
            margin-bottom: 8rem;
        }
        & > *{
            color: #fff;
        }
        .disscussText{
            font-size: 1.8rem;
            @include screen(lg) {
                font-size: 2.4rem;
                padding-right: 2rem;
            }
        }
        a{
            font-weight: 500;
            font-size: 3.4rem;
            text-align: left;
            @include screen(lg) {
                font-size: 4rem;
            }
        }
        @include screen(lg) {
            flex-direction: row;
        }

    }
    .formGrid{
        @include screen(lg) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: 1.2rem;
        }
        margin-bottom: 1.2rem;
    }
    .input-text{
        @include screen(lg) {
            &:nth-child(1){
                grid-row-start: 1;
                grid-column-start: 1;
            }
            &:nth-child(2){
                grid-row-start: 2;
                grid-column-start: 1;
            }
            &:nth-child(3){
                grid-row-start: 3;
                grid-column-start: 1;
            }
            &:nth-child(4){
                grid-row-start: 1;
                grid-row-end: 4;
                grid-column-start: 2;
            }
        }
       
    }
    .input-text{
        transition: all 0.4s ease-in-out;
        border: 1px solid #fff;
        margin-bottom: 1.2rem;
        @include screen(lg) {
            margin-bottom: 0;
        }
        input, textarea{
            outline: none;
            font-size: 1.6rem;
            font-family: $font_regular;
            display: block;
            height: 100%;
            width: 100%;
            background-color: transparent;
            border: none;
            color: #fff;
            padding: 2rem;
            &::placeholder{
                color: #fff; 
            }
        } 
        textarea{
            resize: none;
            padding: 2rem 2rem 5rem;
            @include screen(lg) {
                padding: 2rem;
            }
        }
        &.has-danger{
            border: 1px solid rgb(166, 15, 15);
        }
    }
    .btn-form-send{
        background-color: $color_bg;
        color: $color_btn;
    }
    
    .pristine-error{
        font-size: 1.2rem;
        position: absolute;
        background-color: #fff;
        border-radius: 0.3rem;
        padding: 0.2rem 1.4rem;
        color: $color_link;
        right: -0.6rem;
        top: -0.6rem;
    }
    .btn{
     &:disabled{
        color: #5C5C5C;
        pointer-events: none;
     }
    }
    .formDisclamer{
        font-size: 1.2rem;
        color: #fff;
        margin-bottom: 3rem;
        @include screen(lg) {
            max-width: 49%;
            margin-bottom: 4rem;
        }
    }
}
#modal-success{
    .success-header{
        text-align: center;
        font-weight: 700;
        margin-bottom: 2rem;
        font-family: $font_regular;
        font-size: 2.2rem;
        @include screen(lg) {
            font-size: 3rem;
            margin-bottom: 2.8rem;
        }
    }
    .success-text{
        font-family: $font_regular;
        font-size: 1.6rem;
        text-align: center;
        margin-bottom: 7rem;
        @include screen(lg) {
            font-size: 1.8rem;
            margin-bottom: 4rem;
        }
    }
    .btn{
        max-width: 427px;
        margin: 0 auto;
    }
    .presonsBlock{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .presonsBlockImg{
        margin: 0 auto 0.7rem;
        width: 8.2rem;
        height: 8.2rem;
        border-radius: 50%;
        @include screen(lg) {
            width: 13rem;
            height: 13rem;
            margin: 0 auto 1.2rem;
        }
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: -0.4rem;
            bottom: -0.4rem;
            background-color: #4625EB;
            border-radius: 50%;
        }
    }
    .presoneName{
        text-align: center;
        font-size: 1.3rem;
        text-transform: uppercase;
        font-weight: 700;
        @include screen(lg) {
            font-size: 2.2rem;
        }
    }
    .presonePosition, .presoneSocial{
        text-align: center;
        font-size: 1rem;
        @include screen(lg) {
            font-size: 1.4rem;
        } 
    }
    .presone{
        padding: 0 0.5rem;
        margin-bottom: 9rem;
        @include screen(md) {
            margin-bottom: 5rem;
        }
    }
    .presoneSocial{
        text-decoration: underline;
        display: block;
    }
}