.main-banner {
  padding: 13rem 0 10rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color:transparent;
  @include screen(lg) {
    padding: 16rem 0 15rem;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    min-width: 1000px;
    max-width: 1088px;
    height: 400px;
    top: 0%;
    left: -200px;
    //top: 5rem;
    background: radial-gradient(50% 50% at 50% 50%, rgba(244, 7, 112, 0.5) 0%, rgba(107, 20, 167, 0.1) 81.42%, rgba(57, 10, 221, 0) 100%);
    //filter: blur(100px);
    //-webkit-filter: blur(100px);
    transform: rotate(-13.17deg);
    z-index: 0;
    @include screen(md) {
      height: 600px;
      left: 0;
    }
    @include screen(lg) {
      
      left: 10%;
      top: 8%;
    }
    @include screen(xxxl) {
      left: 20%;
      max-width: 1500px;
    }
  }
  h1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    font-size: 3rem;
    line-height: 1.05;
    margin-bottom: 2rem;
    @include screen(400px) {
      font-size: 4.5rem;
    }
    @include screen(lg) {
      font-size: 8rem;
    }
    @include screen(xxxl) {
      font-size: 9rem;
    }
    // @include screen(xxxl) {
    //   margin-bottom: 0;
    //   font-size: 11rem;
    // }
  }
  .smallHeader {
    font-size: 3rem;
    @include screen(400px) {
      font-size: 4.5rem;
    }
    @include screen(lg) {
      font-size: 3.5rem;
    }
    @include screen(xxl) {
      font-size: 5rem;
    }
    @include screen(xxxl) {
      font-size: 6rem;
    }
    strong {
      color: #4625eb;
      white-space: nowrap;
      @include screen(lg) {
        font-size: 8rem;
      }
      @include screen(xxl) {
        font-size: 12rem;
      }
      // @include screen(xxxl) {
      //   font-size: 18rem;
      // }
    }
  }
  .alighnHeader {
    display: inline-block;
    margin: 0 auto 0 0;
    @include screen(lg) {
      margin: 0 0 0 auto;
    }
  }
  .widthHeader {
    //width: 60%;
    @include screen(lg) {
      width: 60%;
    }
  }
  .bannerText {
    display: block;

    margin-bottom: 2.5rem;
    font-size: 1.6rem;
    margin-top: 0;
    @include screen(lg) {
      max-width: 38%;
      margin-top: -9rem;
      margin-bottom: 0;
      
      padding-bottom: 5rem;
    }
   
    @include screen(xxxl) {
      font-size: 2.1rem;
      padding-bottom: 7rem;
    }
  }
  .counterContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 7rem;
    @include screen(lg) {
      flex-direction: row;
      padding-bottom: 18rem;
    }
    @include screen(xxxl) {
      padding-bottom: 9rem;
    }
  }
  .counterWrapper {
    width: 100%;
    @include screen(sm) {
      width: auto;
    }
  }
  .btn {
    margin-bottom: 4rem;
    @include screen(lg) {
      max-width: 427px;
    }
  }
  .animated-number-counter {
    text-align: right;
    font-size: 6rem;
    line-height: 1;
    font-weight: bold;
    font-family: $font_header;
    color: #5c20dd;
    @include screen(sm) {
      font-size: 7rem;
    }
    @include screen(xxxl) {
      font-size: 9rem;
    }
  }
  .counter {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    
    p {
      font-weight: 600;
      font-size: 1.6rem;
      margin-bottom: 1rem;
      margin-left: 1.5rem;
      @include screen(sm) {
        font-size: 2rem;
      }
      @include screen(xxxl) {
        font-size: 2.5rem;
      }
    }
  }
  .counterImgContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include screen(lg) {
      justify-content: flex-end;
    }
    p {
      font-weight: 600;
      font-size: 1.6rem;
      margin-bottom: 0;
      margin-right: 0.5rem;
     flex-grow: 1;
      @include screen(sm) {
        margin-right: 1.5rem;
        flex-grow: initial; 
        margin-right: 0;
      }
      @include screen(xxxl) {
        font-size: 1.8rem;
      }
    }
  }
  .counterImg {
    width: 7.4rem;
    height: 7.4rem;
    flex-shrink: 0;
    @include screen(xxxl) {
      width: 11.6rem;
      height: 11.6rem;
    }
    &:nth-child(1){
      order:1;
      margin-right: 1rem;
      @include screen(sm) {
        order: initial;
        // margin-right: 1rem;
      }

    }
    &:nth-child(2){
      order:2;
      
      @include screen(sm) {
        order: initial;
      margin-right: 1rem;

      }
    }
  }
  .bannerList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include screen(lg) {
      flex-direction: row;
    }
  }
  .bannerListItem {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    padding: 3rem 0;
    border-bottom: 1px solid rgba(70, 37, 235, 0.5);
    position: relative;
    @include screen(lg) {
      border-bottom: none;
      width: 31%;
    }
    @include screen(xxxl) {
      width: 29%;
    }
    &::after {
      position: absolute;
      content: '';
      display: none;
      top: 0;
      right: -6.333%;
      width: 2px;
      height: 100%;
      background-color: rgba(70, 37, 235, 0.5);
      @include screen(lg) {
        display: block;
      }
      @include screen(xxxl) {
        right: -9.333%;
      }
    }
    &:nth-child(1) {
      .bannerListIcon img {
        width: 211px;
        height: 71px;
      }
    }
    &:nth-child(2) {
      .bannerListIcon img {
        width: 183px;
        height: 63px;
      }
    }
    &:nth-child(3) {
      border-bottom: none;
      &::after {
        display: none;
      }
      .bannerListIcon img {
        width: 236px;
        height: 88px;
      }
    }
  }
  .bannerListIcon {
    display: flex;
    align-items: flex-end;
    height: 9rem;
    margin-bottom: 2rem;
    @include screen(lg) {
      margin-bottom: 3rem;
    }
  }
  .bannerListHeader {
    margin-bottom: 1rem;
    height: auto;
    @include screen(lg) {
      height: 11.4rem;
    }
    @include screen(xl) {
      height: 7.6rem;
    }
    @include screen(xxxl) {
      height: 10rem;
    }
  }
  .bannerListText {
    margin-bottom: 0;
  }
}

.marquee3k__copy {
  box-sizing: border-box;
}

.marquee {
  margin-bottom: 5rem;
  background-color: #4625eb;
  padding: 3.9rem 0;
  overflow: hidden;
  @include screen(lg) {
    padding: 5.5rem 0;
  }
}
.marqueeImgContainer > * {
  vertical-align: middle;
}
.marqueeImg {
  margin-right: 3.5rem;
  display: inline-block;
  @include screen(lg) {
    margin-right: 12rem;
  }
  &:nth-child(1) {
    height: 36px;
    width: 141px;
  }
  &:nth-child(2) {
    height: 36px;
    width: 142px;
  }
  &:nth-child(3) {
    height: 36px;
    width: 94px;
  }
  &:nth-child(4) {
    height: 34px;
    width: 147px;
  }
  &:nth-child(5) {
    height: 40px;
    width: 128px;
  }
  &:nth-child(6) {
    height: 27px;
    width: 147px;
  }
  &:nth-child(7) {
    height: 26px;
    width: 144px;
  }
}
