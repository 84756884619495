.transitions {
  transition: all 0.3s ease;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 1.6rem;
  position: relative;
  @include screen(sm) {
    max-width: 540px;
    padding: 0;
  }
  @include screen(md) {
    max-width: 720px;
  }
  @include screen(lg) {
    max-width: 960px;
  }
  @include screen(xl) {
    max-width: 1140px;
  }
  @include screen(xxl) {
    max-width: 1320px;
  }
  @include screen(xxxl) {
    max-width: 1520px;
  }
}
.btn {
  display: block;
  font-size: 3.2rem;
  line-height: 1;
  font-weight: bold;
  font-family: $font_header;
  text-align: center;
  text-decoration: none;
  width: 100%;
  height: auto;
  transition: 0.5s;
  background: $color_btn;
  color: $color_bg;
  padding: 1.7rem 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  
}
.nowrap {
  white-space: nowrap;
}

.Header {
  font-size: 3.8rem;
  line-height: 1;
  font-weight: bold;
  font-family: $font_header;
  @include screen(xxxl) {
    font-size: 4.8rem;
  }
}
.Text {
  font-size: 1.4rem;
  @include screen(lg) {
    font-size: 1.6rem;
  }
  @include screen(xxxl) {
    font-size: 2rem;
  }
}
.colorFiolet{
  color: $color_link;
}

@keyframes menuAnimate {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}
