.footer-shadow{
    color: #fff;
    position: absolute;
    bottom: 1.6rem;
    font-size: 1.2rem;
    width: 100%;
    a{
        font-size: 1.2rem;
        margin-bottom: 1.8rem; 
        display: inline-block;
        color: #fff;
        @include screen(lg) {
           margin-bottom: 0;
        }
    }
    p{
        font-size: 1.8rem;
        margin-bottom: 0;
        @include screen(lg) {
            font-size: 1.2rem;
        }
    }
    .wrapper{
        text-align: center;
        @include screen(lg) {
            display: flex;
            justify-content: space-between;
        }
    }
}