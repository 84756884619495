.politic{
    padding: 13rem 0 10rem;
  position: relative;
  overflow: hidden;
  @include screen(lg) {
    padding: 23rem 0 15rem;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    min-width: 1000px;
    max-width: 2088px;
    height: 100%;
    max-height: 500px;
    top: 0%;
    left: -200px;
    //top: 5rem;
    background: radial-gradient(50% 50% at 50% 50%, rgba(244, 7, 112, 0.6) 0%, rgba(107, 20, 167, 0.175) 60.42%, rgba(57, 10, 221, 0) 100%);
    filter: blur(100px);
    transform: rotate(-13.17deg);
    @include screen(md) {
      left: 0;
    }
    @include screen(lg) {
      max-height: 600px;
      left: 10%;
      top: 12rem;
    }
  }
  h1{
    font-size: 2.7rem;
    @include screen(lg) {
        font-size: 4rem;
      }
  }
  h2{
    margin: 0 0 2rem;
  font-family: $font_header;
  font-weight: bold;
  color: $color_text;
  font-size: 2.2rem;
    @include screen(lg) {
        margin: 0 0 4rem;
        font-size: 2.7rem;
    }
  }
  p {
    margin: 0 0 1rem;
    font-size: 1.4rem;
    @include screen(lg) {
        font-size: 1.7rem;
        margin: 0 0 2rem;
    }
    &.strong, &.submenu{
        padding-left: 2rem;
    }
  }
}