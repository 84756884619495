.platform{
  padding: 5rem 0 5rem;
 
  @include screen(xxxl) {
      padding: 10rem 0 5rem;
    }
  .platformColumnWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
    @include screen(lg) {
      margin-bottom: 6rem;
    }
  }
  .platformColumn{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include screen(lg) {
      width: 50%;
    }
    &:first-child{
      border-bottom: 2px solid rgba(70, 37, 235, 0.5);
      padding: 0 0 3rem 0;
      @include screen(lg) {
      border-bottom: none;
      padding: 3rem 6rem 3rem 0;
      }
      &::after {
        position: absolute;
        content: '';
        display: none;
        top: 0;
        right: 0;
        width: 2px;
        height: 100%;
        background-color: rgba(70, 37, 235, 0.5);
        @include screen(lg) {
          display: block;
        }
      }
    }
    &:nth-child(2){
      padding: 3rem 0;
      @include screen(lg) {
        padding: 3rem 6rem;
        }
    }
  }
  .platformHeader{
    margin-bottom: 1rem;
    @include screen(lg) {
      margin-bottom: 2rem;
    }
  }
  .platformItem{
    margin-bottom: 1rem;
    padding-left: 27px;
    @include screen(lg) {
      margin-bottom: 2rem;
    }
    &::before{
      content: '';
      position: absolute;
      left: 0;
      top: 6px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.472012 5.21413L0.40982 5.28198C0.184328 5.52797 0.203404 5.91082 0.452232 6.13317L3.22645 8.61225C3.45926 8.82029 3.81284 8.81456 4.03878 8.59908L11.5917 1.39587C11.821 1.17715 11.8402 0.817614 11.6355 0.575685L11.5556 0.48119C11.3341 0.219518 10.9387 0.196746 10.6887 0.431272L4.01852 6.68884C3.80073 6.89316 3.46568 6.90579 3.23312 6.71845L1.28913 5.15244C1.04312 4.95426 0.685477 4.98127 0.472012 5.21413Z' fill='%234625EB'/%3E%3C/svg%3E%0A");  
      width: 12px;
      height: 8px;
      @include screen(lg) {
        width: 17px;
        height: 12px;
        top: 8px;
      }
    }
  }
  .platformImg{
    width: 100%;
    max-width: 343px;
    height: 180px;
    margin: 0 auto;
    @include screen(lg) {
      margin: auto auto 0 0;
    max-width: 460px;
    height: 240px;
    }
    svg{
      width: 100%;
      height: 100%;
    }
  }
  .platformText{
    margin-bottom: 6rem;
  }
  .splide__arrow{
    background: #fff;
  }
  .splide__arrows{
    position: initial;
  }
  .splide__arrow--prev{
    left: 0.5rem;
  }
  .splide__arrow--next{
    right: 0.5rem;
  }
  .splide__arrow svg{
    fill:#3727F3;
  }
  .splide__arrow:disabled{
    display: none;
  }
  .splide{
    padding: 4rem 3.5rem;
    background: linear-gradient(163.79deg, #5029D1 0%, #48A0F8 56.63%, #47A2F9 98.39%);
    border-radius: 1.6rem;
    @include screen(xl) {
      background: linear-gradient(100.35deg, #4E27CF 0%, #46A3F9 99.11%);
      padding: 3vw;
    }
    @include screen(xxxl) {
      padding: 5.5rem;
    }
  }
  .schemeHeader{
    font-size: 4.2rem;
    font-family: $font_header;
    font-weight: bold;
    color: #3727F3;
    width: 100%;
    background-color: #fff;
    padding: 1.5rem;
    text-align: center;
    border-radius: 0.8rem;
    @include screen(xl) {
      border-radius: 1.6rem;
      font-size: 2.1vw;
      margin-bottom: 0;
    }
    @include screen(xxxl) {
      font-size: 4.2rem;
    }
    &.red{
      color: $color_red;
    }
  }
  .deskHeader{
    display: none;
    @include screen(xl) {
      display: grid;
      grid-template-columns: 14.9% 22.5% 37.1% 11.4%;
      gap: 4.7%;
    }
    .schemeHeader{
      margin-bottom: 8.2rem;
      @include screen(xl) {
        margin-bottom: 4vw;
      }
      @include screen(xxxl) {
        margin-bottom: 8.2rem;
      }
    }
  }
  .splide.is-initialized:not(.is-active) .splide__list{
    display: grid;
    grid-template-columns: 14.9% 22.5% 16.2% 16.2% 11.4%;
    gap: 4.7%;
  }
  .splide__slide{
    @include screen(xl) {
      display: flex;
      width: 100%;
      & > *{
        flex: 1;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
      }
    }
    .schemeHeader{
      display: block;
      max-width: 500px;
      margin: 0 auto 2.6rem;
      @include screen(xl) {
        display: none;
      }
    }
    .schemeContainer{
      max-width: 500px;
      margin: 0 auto;
    }
    &:nth-child(1){
      padding-bottom: 2.1rem;
      @include screen(xl) {
        padding-bottom: 1.2vw;
      }
      @include screen(xxxl) {
        padding-bottom: 2.1rem;
      }
      .schemeContainer, .schemeHeader{
        max-width: 217px;
        @include screen(md) {
          max-width: 500px;
        }
      }
      .scemeItem{
       text-align: center;
       margin-bottom: 2rem;
       @include screen(xl) {
        margin-bottom: 0;
       }
      }
    }
    &:nth-child(2){
      .scemeItem{
        padding: 1.2rem 2.5rem 5rem;
        @include screen(xl) {
          padding: 0.7vw 1.2vw 2.4vw;
        }
        @include screen(xxxl) {
          padding: 1.2rem 2.5rem 5rem;
        }
      }
      .scemeItemList li{
        margin-bottom: 0.7rem;
        @include screen(xl) {
         margin-bottom: 0;
        }
      }

    }
    &:nth-child(3),&:nth-child(4),&:nth-child(5){
      align-items: center;
      .scemeItem{
        height: auto;
        @include screen(xl) {
          height: 10vw;
        }
        @include screen(xxxl) {
          height: 20rem;
        }
      }
    }
    &:nth-child(3){
      padding-bottom: 2.1rem;
      @include screen(xl) {
        padding-bottom: 1.2vw;
      }
      @include screen(xxxl) {
        padding-bottom: 2.1rem;
      }
      .schemeContainer{
        max-width: 240px;
        @include screen(md) {
          max-width: 500px;
        }
      }
      .schemeHeader{
        max-width: 262px;
        @include screen(md) {
          max-width: 500px;
        }
      }
      .scemeItem{
        background-color: $color_bg;
        color: #000;
        font-weight: 500;
        padding: 2.5rem 2.5rem 5rem;
        @include screen(xl) {
          padding: 1.2vw;
        }
        @include screen(xxxl) {
          padding:2.5rem;
        }
      }
      .scemeItemText{
        font-size: 1.6rem;
        @include screen(xl) {
          font-size: 0.7vw;
        }
        @include screen(xxxl) {
          font-size: 1.3rem;
        }
      }
    }
    &:nth-child(4){
      padding-bottom: 2.1rem;
      @include screen(xl) {
        padding-bottom: 1.2vw;
      }
      @include screen(xxxl) {
        padding-bottom: 2.1rem;
      }
      .scemeItem{
        padding: 2.5rem 2.5rem 5rem;
        @include screen(xl) {
          padding: 1.2vw;
        }
        @include screen(xxxl) {
          padding:2.5rem;
        }
      }
      .schemeContainer{
        max-width: 240px;
        @include screen(md) {
          max-width: 500px;
        }
      }
      .schemeHeader{
        max-width: 262px;
        @include screen(md) {
          max-width: 500px;
        }
      }
      .scemeItemHeader{
        font-size:3rem;
        margin-bottom: 1rem;
        @include screen(xl) {
          font-size: 1.1vw;
          margin-bottom: 0.4vw;
        }
        @include screen(xxxl) {
          font-size: 2.5rem;
          margin-bottom: 1rem;
        }
      }
      .scemeItemText{
        font-size: 1.6rem;
        margin-bottom: 1rem;
        @include screen(xl) {
          font-size: 0.7vw;
          margin-bottom: 0.4vw;
        }
        @include screen(xxxl) {
          font-size: 1.3rem;
          margin-bottom: 1rem;
        }
      }
    }
    &:nth-child(5){
      .schemeContainer, .schemeHeader{
        max-width: 190px;
        @include screen(md) {
          max-width: 500px;
        }
      }
      .scemeItem{
        padding: 1rem;
        @include screen(xl) {
          padding: 0.4vw;
        }
        @include screen(xxxl) {
          padding:0.8rem;
        }
      }
    }
  }
 
  .scemeItem{
    border: 1px solid #fff;
    padding: 0.9rem 2.5rem;
    border-radius: 0.8rem;
    color: #fff;
    
    @include screen(xl) {
      padding: 0.5vw 1.2vw;
      &::after{
        content: '';
        position: absolute;
        right: -4.4rem;
        top: 50%;
        // width: 2.4vw;
        // height: 0.6vw;
        width: 3.6rem;
        height: 1.3rem;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg width='48' height='13' viewBox='0 0 48 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M47.0385 6.90766C47.3683 6.57788 47.3683 6.04321 47.0385 5.71343L41.6645 0.339422C41.3347 0.00964642 40.8001 0.00964642 40.4703 0.339422C40.1405 0.669199 40.1405 1.20387 40.4703 1.53365L45.2472 6.31055L40.4703 11.0874C40.1405 11.4172 40.1405 11.9519 40.4703 12.2817C40.8001 12.6114 41.3347 12.6114 41.6645 12.2817L47.0385 6.90766ZM0.441406 7.15499H46.4414V5.4661H0.441406V7.15499Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }
    @include screen(xxl) {
      &::after{
        right: -4.6rem;
      }
    }
    @include screen(xxxl) {
      padding: 0.9rem 2.5rem;
      &::after{
        right: -5.6rem;
        width: 4.6rem;
        height: 1.3rem;
      }
    }
  }
  .scemeItemHeader{
    line-height: 1;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 2.1rem;
    font-weight: 500;
    @include screen(xl) {
      font-size: 1.1vw;
    }
    @include screen(xxxl) {
      font-size: 2.5rem;
    }
  }
  .scemeItemText{
    line-height: 1;
    margin-bottom: 0;
    font-size: 1.3rem;
    @include screen(xl) {
      font-size: 0.7vw;
    }
    @include screen(xxxl) {
      font-size:  1.3rem;
    }
  }
  .scemeItemList{
    margin-bottom: 2rem;
    @include screen(xl) {
      margin-bottom: 1vw;
    }
    @include screen(xxxl) {
      margin-bottom: 2rem;
    }
    li{
      padding-left: 1.5rem;
      @include screen(xl) {
        padding-left: 0.8vw;
      }
      @include screen(xxxl) {
        padding-left: 1.5rem;
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        width: 1rem;
        height: 0.7rem;
        left: 0;
        top: 0.3rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.393343 4.34511L0.341517 4.40165C0.153607 4.60664 0.169503 4.92568 0.37686 5.11098L2.68871 7.17688C2.88272 7.35024 3.17737 7.34547 3.36565 7.1659L9.65972 1.16323C9.85083 0.980958 9.86685 0.681345 9.69626 0.479738L9.62963 0.400991C9.44511 0.182932 9.11556 0.163955 8.90723 0.359394L3.34877 5.57403C3.16727 5.7443 2.88807 5.75483 2.69427 5.59871L1.07428 4.2937C0.869267 4.12855 0.571231 4.15105 0.393343 4.34511Z' fill='white'/%3E%3C/svg%3E%0A");
        @include screen(xl) {
          width: 0.6vw;
          height: 0.4vw;
          top: 0.2vw;
        }
        @include screen(xxxl) {
          width: 1rem;
          height: 0.7rem;
          top: 0.3rem;
        }
      }
    }
  }
  .scemeDisclamer{
    text-align: center;
    color: #fff;
    margin-top: 0.8rem;
    @include screen(xl) {
      margin-top: 0.5vw;
    }
    @include screen(xxxl) {
      margin-top: 0.8rem;
    }
  }
  .dataBlocks {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    span{
      width: 100%;
      display: block;
      height: 4.6rem;
      background-color: #3727F3;
      border-radius: 1rem;
      margin-bottom: 1rem;
      @include screen(xl) {
        margin-bottom: 0;
        height: 2vw;
        border-radius: 0.5vw;
      }
      @include screen(xxxl) {
        height: 3.8rem;
        border-radius: 1rem;
      }
    }
  }
  .splide__pagination__page{
    opacity: 1;
  }
}