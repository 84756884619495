.services{
    padding: 5rem 0 5rem;
    @include screen(xxxl) {
        padding: 10rem 0 5rem;
    }
    .servicesText{
        font-weight: 500;
        max-width: 670px;
        font-size: 1.8rem;
        margin-bottom: 3rem;
        @include screen(lg) {
            font-size: 2.4rem;
            margin-bottom: 6rem;
        }
    }
    h2{
        line-height: 1;
        margin-bottom: 1.5rem;
        @include screen(lg) {
            margin-bottom: 2rem;
        }
    }
    h3{
        color: #4625EB;
        font-size: 3.8rem;
        margin-bottom: 3rem;
        @include screen(lg) {
            font-size: 5.4rem;
            margin-bottom: 4rem;
        }
    }
    .gradientText{
        //background: linear-gradient(146.34deg, #F70872 14.71%,  #F70872 34.2%, #CB118F 58.62%, #8E18BA 88.23%);
        background: linear-gradient(180deg, #F70872 14.71%, #CB118F 41.62%, #A017AF 64.2%, #8E18BA 97.22%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .servicesList{
        margin-bottom: 3rem;
        @include screen(lg) {
            margin-bottom: 6rem;
            display: flex;
            justify-content: space-between;
        }
    }
    .servicesListItem{
        padding: 2rem;
        width: 100%;
        background: linear-gradient(135.94deg, #4E21CD 0%, #45A3F9 98.42%);
        color: #fff;
        margin-bottom: 3rem;
        @include screen(lg) {
            padding: 5rem 2.5rem 2.5rem;
            margin-bottom: 0;
            width: 31%;
        }
    }
    .servicesImg{
        margin-bottom: 1.5rem;
        width: 20rem;
        height: auto;
        @include screen(xl) {
            width: 25rem;
        }
    }
    .servicesItemText{
        font-size: 1.4rem;
        margin-bottom: 2rem;
        @include screen(xl) {
            margin-bottom: 3rem;
            font-size: 2.2rem;
        }
    }
    .servicesItemDisclamer{
        font-size: 0.9rem;
        @include screen(xl) {
            font-size: 1.2rem;
        }
    }
    .quoteBlockName{
        font-size: 1.4rem;
        @include screen(lg) {
            font-size: 2rem;
        }
    }
    .quoteBlock{
        display: flex;
        flex-direction: column-reverse;
        @include screen(lg) {
            flex-direction: row;
        }
    }
    .quoteBlockImg{
        margin-bottom: 0.8rem;
        width: 8rem;
        height: 8rem;
        @include screen(lg) {
            margin-top: 1rem;
            width: 11.8rem;
            height: 11.8rem;
        }
    }
   .quoteBlockText{
    font-size: 1.4rem;
    max-width: 740px;
    z-index: 1;
    &::before, &::after{
        content: open-quote;
        position: absolute;
        font-size: 15rem;
        line-height: 1;
        z-index: 0;
        color: rgba(70, 37, 235, 0.42);
        font-weight: bold;
        @include screen(lg) {
            font-size: 20rem;
        }
    }
    &::before{
        content: open-quote;
        left: -0.9rem;
        top: -7rem;
        @include screen(lg) {
            left: 0;
        }
    }
    &::after{
        content: close-quote;
        right: -0.9rem;
        bottom: -3rem;
        @include screen(lg) {
            right: 0;
            bottom: -2rem;
        }
    }
    padding: 1rem 0.9rem 1.5rem;
    @include screen(lg) {
        font-size: 2.4rem;
        padding: 3.4rem 2rem 3.4rem 8rem;
    }
   }
}