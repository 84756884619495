@import 'fonts';
html {
  font-size: 62.5%;
  input[type='button'] {
    cursor: pointer;
  }
  input[disabled] {
    cursor: default;
  }
}
body * {
  box-sizing: border-box;
  position: relative;
}
html,
body {
  height: 100%;
  width: 100%;
}
body {
  font-family: $font_regular;
  font-size: $font_size;
  line-height: 1.25;
  margin: 0;
  color: $color_text;
  min-width: 320px;
  background: $color_bg;
}
a {
  color: $color_link;
  text-decoration: none;
  font-size: 1.5rem;
  text-align: center;
  &:active {
    outline: 0;
  }
  &:hover {
    outline: 0;
    text-decoration: none;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
h1,
h2,
h3 {
  margin: 0 0 4rem;
  font-family: $font_header;
  font-weight: bold;
  color: $color_text;
  font-size: 2.7rem;
  line-height: 1.25;
  text-align: left;
  text-transform: none;
}
h2{
  color: $color_link;
  font-size: 6rem;
  line-height: 1;
  margin-bottom: 3rem;
  @include screen(400px) {
    font-size: 8rem;
  }
  @include screen(xxxl) {
    font-size: 10rem;
    margin-bottom: 6rem;
  }
}
p {
  margin: 0 0 0.5rem;
  font-size: 1.7rem;
}
.strong {
  font-weight: bold;
}
svg {
  display: block;
}
/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}