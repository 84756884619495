@font-face {
  font-family: 'Bebas Neue';
  src: url('../fonts/BebasNeue-Regular.woff2') format('woff2'), url('../fonts/BebasNeue-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Bebas Neue';
  src: url('../fonts/BebasNeue-Bold.woff2') format('woff2'), url('../fonts/BebasNeue-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: url('../fonts/IBMPlexMono-Medium.woff2') format('woff2'), url('../fonts/IBMPlexMono-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: url('../fonts/IBMPlexMono-SemiBold.woff2') format('woff2'), url('../fonts/IBMPlexMono-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: url('../fonts/IBMPlexMono-Bold.woff2') format('woff2'), url('../fonts/IBMPlexMono-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: url('../fonts/IBMPlexMono-Regular.woff2') format('woff2'), url('../fonts/IBMPlexMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
