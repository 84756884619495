@charset "UTF-8";
/*mixins*/
/* variables */
.transitions {
  transition: all 0.3s ease;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 1.6rem;
  position: relative;
}
@media (min-width: 576px) {
  .wrapper {
    max-width: 540px;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .wrapper {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .wrapper {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .wrapper {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .wrapper {
    max-width: 1320px;
  }
}
@media (min-width: 1920px) {
  .wrapper {
    max-width: 1520px;
  }
}

.btn {
  display: block;
  font-size: 3.2rem;
  line-height: 1;
  font-weight: bold;
  font-family: "Bebas Neue", Arial, sans-serif;
  text-align: center;
  text-decoration: none;
  width: 100%;
  height: auto;
  transition: 0.5s;
  background: #4625eb;
  color: #ffffff;
  padding: 1.7rem 1rem;
  outline: none;
  border: none;
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.Header {
  font-size: 3.8rem;
  line-height: 1;
  font-weight: bold;
  font-family: "Bebas Neue", Arial, sans-serif;
}
@media (min-width: 1920px) {
  .Header {
    font-size: 4.8rem;
  }
}

.Text {
  font-size: 1.4rem;
}
@media (min-width: 992px) {
  .Text {
    font-size: 1.6rem;
  }
}
@media (min-width: 1920px) {
  .Text {
    font-size: 2rem;
  }
}

.colorFiolet {
  color: #4625eb;
}

@keyframes menuAnimate {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}
body {
  -webkit-text-size-adjust: 100%;
}

input[type=submit],
input[type=text],
input[type=password],
input[type=file],
textarea {
  -webkit-appearance: none;
}

textarea,
input[type=submit],
input[type=text] {
  -webkit-border-radius: 0;
}

input[type=image] {
  padding: 0;
  border: none;
}

img {
  vertical-align: top;
  border: none;
}

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

input, textarea, select {
  vertical-align: middle;
  color: #000;
}

label {
  vertical-align: middle;
}

input:focus {
  outline: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

figure {
  margin: 1em 40px;
}

audio {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}

canvas {
  display: inline-block;
  vertical-align: baseline;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

video {
  display: inline-block;
  vertical-align: baseline;
}

[hidden] {
  display: none;
}

template {
  display: none;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b {
  font-weight: bold;
}

strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

img {
  border: 0;
  border: none;
  max-width: 100%;
}

hr {
  box-sizing: content-box;
  height: 0;
}

small {
  font-size: 80%;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

pre {
  overflow: auto;
  font-size: 1em;
}

code {
  font-size: 1em;
}

kbd {
  font-size: 1em;
}

samp {
  font-size: 1em;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("../fonts/BebasNeue-Regular.woff2") format("woff2"), url("../fonts/BebasNeue-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Bebas Neue";
  src: url("../fonts/BebasNeue-Bold.woff2") format("woff2"), url("../fonts/BebasNeue-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono";
  src: url("../fonts/IBMPlexMono-Medium.woff2") format("woff2"), url("../fonts/IBMPlexMono-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono";
  src: url("../fonts/IBMPlexMono-SemiBold.woff2") format("woff2"), url("../fonts/IBMPlexMono-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono";
  src: url("../fonts/IBMPlexMono-Bold.woff2") format("woff2"), url("../fonts/IBMPlexMono-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono";
  src: url("../fonts/IBMPlexMono-Regular.woff2") format("woff2"), url("../fonts/IBMPlexMono-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html {
  font-size: 62.5%;
}
html input[type=button] {
  cursor: pointer;
}
html input[disabled] {
  cursor: default;
}

body * {
  box-sizing: border-box;
  position: relative;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: "IBM Plex Mono", Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 1.25;
  margin: 0;
  color: #000000;
  min-width: 320px;
  background: #ffffff;
}

a {
  color: #4625eb;
  text-decoration: none;
  font-size: 1.5rem;
  text-align: center;
}
a:active {
  outline: 0;
}
a:hover {
  outline: 0;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3 {
  margin: 0 0 4rem;
  font-family: "Bebas Neue", Arial, sans-serif;
  font-weight: bold;
  color: #000000;
  font-size: 2.7rem;
  line-height: 1.25;
  text-align: left;
  text-transform: none;
}

h2 {
  color: #4625eb;
  font-size: 6rem;
  line-height: 1;
  margin-bottom: 3rem;
}
@media (min-width: 400px) {
  h2 {
    font-size: 8rem;
  }
}
@media (min-width: 1920px) {
  h2 {
    font-size: 10rem;
    margin-bottom: 6rem;
  }
}

p {
  margin: 0 0 0.5rem;
  font-size: 1.7rem;
}

.strong {
  font-weight: bold;
}

svg {
  display: block;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
header .flex-between {
  align-items: flex-end;
  overflow: hidden;
  padding: 2rem 0;
  position: relative;
}
@media (min-width: 992px) {
  header .flex-between {
    padding: 3rem 0 0;
  }
}
header .flex-between::after {
  content: "";
  display: block;
  position: absolute;
  background: linear-gradient(146.34deg, #f70872 14.71%, #cb118f 31.62%, #a017af 44.2%, #8e18ba 57.22%, #5c20dd 68.62%, #3727f3 88.23%);
  bottom: 0;
  height: 2px;
  width: 100%;
  max-width: 720px;
  right: 0%;
  animation-name: menuAnimate;
  animation-duration: 2s;
}
header .logo {
  width: 5.7rem;
  height: auto;
  z-index: 2000;
}
@media (min-width: 992px) {
  header .logo {
    width: 13.9rem;
  }
}
header .nav__list {
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  header .nav__list {
    justify-content: flex-end;
    flex-direction: row;
  }
}
header .nav__list li {
  padding: 1rem 0;
}
@media (min-width: 992px) {
  header .nav__list li {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    padding-right: 5rem;
  }
  header .nav__list li:last-child {
    padding-right: 0;
  }
}
header .nav__item a {
  font-size: 2.2rem;
  font-weight: 500;
  position: relative;
  display: inline-block;
  color: #000000;
  cursor: pointer;
  letter-spacing: -0.002rem;
  text-transform: uppercase;
}
header .nav__item a::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: attr(data-text);
  opacity: 0;
  font-weight: bold;
  transition: opacity 0.2s linear;
}
header .nav__item a:active, header .nav__item a:hover {
  color: #4625eb;
}
header .nav__item a:active::before, header .nav__item a:hover::before {
  opacity: 1;
}
header.mob-menu-show .nav-toggle-btn:before {
  transform: rotate3d(0, 0, 1, 405deg);
  transition: 0.6s;
  margin: 0;
  position: relative;
  top: 2px;
}
header.mob-menu-show .nav-toggle-btn:after {
  transform: rotate3d(0, 0, 1, 495deg);
  transition: 0.6s;
  margin: 0;
  position: relative;
  top: 0;
}
header.mob-menu-show .nav-holder {
  opacity: 1 !important;
  right: 0 !important;
}
header .nav-holder {
  text-align: center;
  display: block;
  position: fixed;
  top: 0;
  left: auto;
  bottom: 0;
  right: -110%;
  width: 100%;
  height: 100%;
  z-index: 1000;
  box-sizing: border-box;
  padding: 7rem 1rem 0;
  background: #fff;
  opacity: 0;
  overflow: auto;
  transition: right 0.4s ease, opacity 0.4s ease;
}
@media (min-width: 992px) {
  header .nav-holder {
    width: 100%;
    position: initial;
    padding: 0;
    opacity: 1;
    background: none;
  }
}
header .nav-toggle-btn {
  z-index: 2000;
  cursor: pointer;
  outline: 0;
  background: none;
  border: none;
  position: absolute;
  width: 31px;
  height: 31px;
  padding: 0;
  left: auto;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: block;
}
@media (min-width: 992px) {
  header .nav-toggle-btn {
    display: none;
  }
}
header .nav-toggle-btn:before, header .nav-toggle-btn:after {
  content: "";
  display: block;
  background: #000;
  width: 31px;
  border-radius: 2px;
  height: 2px;
  margin: 0 0 7px;
}
header .nav-toggle-btn:after {
  margin: 0;
}

.prevent-scroll {
  height: 100%;
  overflow: hidden;
}

.main-banner {
  padding: 13rem 0 10rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: transparent;
}
@media (min-width: 992px) {
  .main-banner {
    padding: 16rem 0 15rem;
  }
}
.main-banner:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  min-width: 1000px;
  max-width: 1088px;
  height: 400px;
  top: 0%;
  left: -200px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(244, 7, 112, 0.5) 0%, rgba(107, 20, 167, 0.1) 81.42%, rgba(57, 10, 221, 0) 100%);
  transform: rotate(-13.17deg);
  z-index: 0;
}
@media (min-width: 768px) {
  .main-banner:before {
    height: 600px;
    left: 0;
  }
}
@media (min-width: 992px) {
  .main-banner:before {
    left: 10%;
    top: 8%;
  }
}
@media (min-width: 1920px) {
  .main-banner:before {
    left: 20%;
    max-width: 1500px;
  }
}
.main-banner h1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  font-size: 3rem;
  line-height: 1.05;
  margin-bottom: 2rem;
}
@media (min-width: 400px) {
  .main-banner h1 {
    font-size: 4.5rem;
  }
}
@media (min-width: 992px) {
  .main-banner h1 {
    font-size: 8rem;
  }
}
@media (min-width: 1920px) {
  .main-banner h1 {
    font-size: 9rem;
  }
}
.main-banner .smallHeader {
  font-size: 3rem;
}
@media (min-width: 400px) {
  .main-banner .smallHeader {
    font-size: 4.5rem;
  }
}
@media (min-width: 992px) {
  .main-banner .smallHeader {
    font-size: 3.5rem;
  }
}
@media (min-width: 1400px) {
  .main-banner .smallHeader {
    font-size: 5rem;
  }
}
@media (min-width: 1920px) {
  .main-banner .smallHeader {
    font-size: 6rem;
  }
}
.main-banner .smallHeader strong {
  color: #4625eb;
  white-space: nowrap;
}
@media (min-width: 992px) {
  .main-banner .smallHeader strong {
    font-size: 8rem;
  }
}
@media (min-width: 1400px) {
  .main-banner .smallHeader strong {
    font-size: 12rem;
  }
}
.main-banner .alighnHeader {
  display: inline-block;
  margin: 0 auto 0 0;
}
@media (min-width: 992px) {
  .main-banner .alighnHeader {
    margin: 0 0 0 auto;
  }
}
@media (min-width: 992px) {
  .main-banner .widthHeader {
    width: 60%;
  }
}
.main-banner .bannerText {
  display: block;
  margin-bottom: 2.5rem;
  font-size: 1.6rem;
  margin-top: 0;
}
@media (min-width: 992px) {
  .main-banner .bannerText {
    max-width: 38%;
    margin-top: -9rem;
    margin-bottom: 0;
    padding-bottom: 5rem;
  }
}
@media (min-width: 1920px) {
  .main-banner .bannerText {
    font-size: 2.1rem;
    padding-bottom: 7rem;
  }
}
.main-banner .counterContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 7rem;
}
@media (min-width: 992px) {
  .main-banner .counterContainer {
    flex-direction: row;
    padding-bottom: 18rem;
  }
}
@media (min-width: 1920px) {
  .main-banner .counterContainer {
    padding-bottom: 9rem;
  }
}
.main-banner .counterWrapper {
  width: 100%;
}
@media (min-width: 576px) {
  .main-banner .counterWrapper {
    width: auto;
  }
}
.main-banner .btn {
  margin-bottom: 4rem;
}
@media (min-width: 992px) {
  .main-banner .btn {
    max-width: 427px;
  }
}
.main-banner .animated-number-counter {
  text-align: right;
  font-size: 6rem;
  line-height: 1;
  font-weight: bold;
  font-family: "Bebas Neue", Arial, sans-serif;
  color: #5c20dd;
}
@media (min-width: 576px) {
  .main-banner .animated-number-counter {
    font-size: 7rem;
  }
}
@media (min-width: 1920px) {
  .main-banner .animated-number-counter {
    font-size: 9rem;
  }
}
.main-banner .counter {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
}
.main-banner .counter p {
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
}
@media (min-width: 576px) {
  .main-banner .counter p {
    font-size: 2rem;
  }
}
@media (min-width: 1920px) {
  .main-banner .counter p {
    font-size: 2.5rem;
  }
}
.main-banner .counterImgContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .main-banner .counterImgContainer {
    justify-content: flex-end;
  }
}
.main-banner .counterImgContainer p {
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 0;
  margin-right: 0.5rem;
  flex-grow: 1;
}
@media (min-width: 576px) {
  .main-banner .counterImgContainer p {
    margin-right: 1.5rem;
    flex-grow: initial;
    margin-right: 0;
  }
}
@media (min-width: 1920px) {
  .main-banner .counterImgContainer p {
    font-size: 1.8rem;
  }
}
.main-banner .counterImg {
  width: 7.4rem;
  height: 7.4rem;
  flex-shrink: 0;
}
@media (min-width: 1920px) {
  .main-banner .counterImg {
    width: 11.6rem;
    height: 11.6rem;
  }
}
.main-banner .counterImg:nth-child(1) {
  order: 1;
  margin-right: 1rem;
}
@media (min-width: 576px) {
  .main-banner .counterImg:nth-child(1) {
    order: initial;
  }
}
.main-banner .counterImg:nth-child(2) {
  order: 2;
}
@media (min-width: 576px) {
  .main-banner .counterImg:nth-child(2) {
    order: initial;
    margin-right: 1rem;
  }
}
.main-banner .bannerList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .main-banner .bannerList {
    flex-direction: row;
  }
}
.main-banner .bannerListItem {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem 0;
  border-bottom: 1px solid rgba(70, 37, 235, 0.5);
  position: relative;
}
@media (min-width: 992px) {
  .main-banner .bannerListItem {
    border-bottom: none;
    width: 31%;
  }
}
@media (min-width: 1920px) {
  .main-banner .bannerListItem {
    width: 29%;
  }
}
.main-banner .bannerListItem::after {
  position: absolute;
  content: "";
  display: none;
  top: 0;
  right: -6.333%;
  width: 2px;
  height: 100%;
  background-color: rgba(70, 37, 235, 0.5);
}
@media (min-width: 992px) {
  .main-banner .bannerListItem::after {
    display: block;
  }
}
@media (min-width: 1920px) {
  .main-banner .bannerListItem::after {
    right: -9.333%;
  }
}
.main-banner .bannerListItem:nth-child(1) .bannerListIcon img {
  width: 211px;
  height: 71px;
}
.main-banner .bannerListItem:nth-child(2) .bannerListIcon img {
  width: 183px;
  height: 63px;
}
.main-banner .bannerListItem:nth-child(3) {
  border-bottom: none;
}
.main-banner .bannerListItem:nth-child(3)::after {
  display: none;
}
.main-banner .bannerListItem:nth-child(3) .bannerListIcon img {
  width: 236px;
  height: 88px;
}
.main-banner .bannerListIcon {
  display: flex;
  align-items: flex-end;
  height: 9rem;
  margin-bottom: 2rem;
}
@media (min-width: 992px) {
  .main-banner .bannerListIcon {
    margin-bottom: 3rem;
  }
}
.main-banner .bannerListHeader {
  margin-bottom: 1rem;
  height: auto;
}
@media (min-width: 992px) {
  .main-banner .bannerListHeader {
    height: 11.4rem;
  }
}
@media (min-width: 1200px) {
  .main-banner .bannerListHeader {
    height: 7.6rem;
  }
}
@media (min-width: 1920px) {
  .main-banner .bannerListHeader {
    height: 10rem;
  }
}
.main-banner .bannerListText {
  margin-bottom: 0;
}

.marquee3k__copy {
  box-sizing: border-box;
}

.marquee {
  margin-bottom: 5rem;
  background-color: #4625eb;
  padding: 3.9rem 0;
  overflow: hidden;
}
@media (min-width: 992px) {
  .marquee {
    padding: 5.5rem 0;
  }
}

.marqueeImgContainer > * {
  vertical-align: middle;
}

.marqueeImg {
  margin-right: 3.5rem;
  display: inline-block;
}
@media (min-width: 992px) {
  .marqueeImg {
    margin-right: 12rem;
  }
}
.marqueeImg:nth-child(1) {
  height: 36px;
  width: 141px;
}
.marqueeImg:nth-child(2) {
  height: 36px;
  width: 142px;
}
.marqueeImg:nth-child(3) {
  height: 36px;
  width: 94px;
}
.marqueeImg:nth-child(4) {
  height: 34px;
  width: 147px;
}
.marqueeImg:nth-child(5) {
  height: 40px;
  width: 128px;
}
.marqueeImg:nth-child(6) {
  height: 27px;
  width: 147px;
}
.marqueeImg:nth-child(7) {
  height: 26px;
  width: 144px;
}

.platform {
  padding: 5rem 0 5rem;
}
@media (min-width: 1920px) {
  .platform {
    padding: 10rem 0 5rem;
  }
}
.platform .platformColumnWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
}
@media (min-width: 992px) {
  .platform .platformColumnWrapper {
    margin-bottom: 6rem;
  }
}
.platform .platformColumn {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .platform .platformColumn {
    width: 50%;
  }
}
.platform .platformColumn:first-child {
  border-bottom: 2px solid rgba(70, 37, 235, 0.5);
  padding: 0 0 3rem 0;
}
@media (min-width: 992px) {
  .platform .platformColumn:first-child {
    border-bottom: none;
    padding: 3rem 6rem 3rem 0;
  }
}
.platform .platformColumn:first-child::after {
  position: absolute;
  content: "";
  display: none;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background-color: rgba(70, 37, 235, 0.5);
}
@media (min-width: 992px) {
  .platform .platformColumn:first-child::after {
    display: block;
  }
}
.platform .platformColumn:nth-child(2) {
  padding: 3rem 0;
}
@media (min-width: 992px) {
  .platform .platformColumn:nth-child(2) {
    padding: 3rem 6rem;
  }
}
.platform .platformHeader {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .platform .platformHeader {
    margin-bottom: 2rem;
  }
}
.platform .platformItem {
  margin-bottom: 1rem;
  padding-left: 27px;
}
@media (min-width: 992px) {
  .platform .platformItem {
    margin-bottom: 2rem;
  }
}
.platform .platformItem::before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.472012 5.21413L0.40982 5.28198C0.184328 5.52797 0.203404 5.91082 0.452232 6.13317L3.22645 8.61225C3.45926 8.82029 3.81284 8.81456 4.03878 8.59908L11.5917 1.39587C11.821 1.17715 11.8402 0.817614 11.6355 0.575685L11.5556 0.48119C11.3341 0.219518 10.9387 0.196746 10.6887 0.431272L4.01852 6.68884C3.80073 6.89316 3.46568 6.90579 3.23312 6.71845L1.28913 5.15244C1.04312 4.95426 0.685477 4.98127 0.472012 5.21413Z' fill='%234625EB'/%3E%3C/svg%3E%0A");
  width: 12px;
  height: 8px;
}
@media (min-width: 992px) {
  .platform .platformItem::before {
    width: 17px;
    height: 12px;
    top: 8px;
  }
}
.platform .platformImg {
  width: 100%;
  max-width: 343px;
  height: 180px;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .platform .platformImg {
    margin: auto auto 0 0;
    max-width: 460px;
    height: 240px;
  }
}
.platform .platformImg svg {
  width: 100%;
  height: 100%;
}
.platform .platformText {
  margin-bottom: 6rem;
}
.platform .splide__arrow {
  background: #fff;
}
.platform .splide__arrows {
  position: initial;
}
.platform .splide__arrow--prev {
  left: 0.5rem;
}
.platform .splide__arrow--next {
  right: 0.5rem;
}
.platform .splide__arrow svg {
  fill: #3727F3;
}
.platform .splide__arrow:disabled {
  display: none;
}
.platform .splide {
  padding: 4rem 3.5rem;
  background: linear-gradient(163.79deg, #5029D1 0%, #48A0F8 56.63%, #47A2F9 98.39%);
  border-radius: 1.6rem;
}
@media (min-width: 1200px) {
  .platform .splide {
    background: linear-gradient(100.35deg, #4E27CF 0%, #46A3F9 99.11%);
    padding: 3vw;
  }
}
@media (min-width: 1920px) {
  .platform .splide {
    padding: 5.5rem;
  }
}
.platform .schemeHeader {
  font-size: 4.2rem;
  font-family: "Bebas Neue", Arial, sans-serif;
  font-weight: bold;
  color: #3727F3;
  width: 100%;
  background-color: #fff;
  padding: 1.5rem;
  text-align: center;
  border-radius: 0.8rem;
}
@media (min-width: 1200px) {
  .platform .schemeHeader {
    border-radius: 1.6rem;
    font-size: 2.1vw;
    margin-bottom: 0;
  }
}
@media (min-width: 1920px) {
  .platform .schemeHeader {
    font-size: 4.2rem;
  }
}
.platform .schemeHeader.red {
  color: #F70872;
}
.platform .deskHeader {
  display: none;
}
@media (min-width: 1200px) {
  .platform .deskHeader {
    display: grid;
    grid-template-columns: 14.9% 22.5% 37.1% 11.4%;
    gap: 4.7%;
  }
}
.platform .deskHeader .schemeHeader {
  margin-bottom: 8.2rem;
}
@media (min-width: 1200px) {
  .platform .deskHeader .schemeHeader {
    margin-bottom: 4vw;
  }
}
@media (min-width: 1920px) {
  .platform .deskHeader .schemeHeader {
    margin-bottom: 8.2rem;
  }
}
.platform .splide.is-initialized:not(.is-active) .splide__list {
  display: grid;
  grid-template-columns: 14.9% 22.5% 16.2% 16.2% 11.4%;
  gap: 4.7%;
}
@media (min-width: 1200px) {
  .platform .splide__slide {
    display: flex;
    width: 100%;
  }
  .platform .splide__slide > * {
    flex: 1;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }
}
.platform .splide__slide .schemeHeader {
  display: block;
  max-width: 500px;
  margin: 0 auto 2.6rem;
}
@media (min-width: 1200px) {
  .platform .splide__slide .schemeHeader {
    display: none;
  }
}
.platform .splide__slide .schemeContainer {
  max-width: 500px;
  margin: 0 auto;
}
.platform .splide__slide:nth-child(1) {
  padding-bottom: 2.1rem;
}
@media (min-width: 1200px) {
  .platform .splide__slide:nth-child(1) {
    padding-bottom: 1.2vw;
  }
}
@media (min-width: 1920px) {
  .platform .splide__slide:nth-child(1) {
    padding-bottom: 2.1rem;
  }
}
.platform .splide__slide:nth-child(1) .schemeContainer, .platform .splide__slide:nth-child(1) .schemeHeader {
  max-width: 217px;
}
@media (min-width: 768px) {
  .platform .splide__slide:nth-child(1) .schemeContainer, .platform .splide__slide:nth-child(1) .schemeHeader {
    max-width: 500px;
  }
}
.platform .splide__slide:nth-child(1) .scemeItem {
  text-align: center;
  margin-bottom: 2rem;
}
@media (min-width: 1200px) {
  .platform .splide__slide:nth-child(1) .scemeItem {
    margin-bottom: 0;
  }
}
.platform .splide__slide:nth-child(2) .scemeItem {
  padding: 1.2rem 2.5rem 5rem;
}
@media (min-width: 1200px) {
  .platform .splide__slide:nth-child(2) .scemeItem {
    padding: 0.7vw 1.2vw 2.4vw;
  }
}
@media (min-width: 1920px) {
  .platform .splide__slide:nth-child(2) .scemeItem {
    padding: 1.2rem 2.5rem 5rem;
  }
}
.platform .splide__slide:nth-child(2) .scemeItemList li {
  margin-bottom: 0.7rem;
}
@media (min-width: 1200px) {
  .platform .splide__slide:nth-child(2) .scemeItemList li {
    margin-bottom: 0;
  }
}
.platform .splide__slide:nth-child(3), .platform .splide__slide:nth-child(4), .platform .splide__slide:nth-child(5) {
  align-items: center;
}
.platform .splide__slide:nth-child(3) .scemeItem, .platform .splide__slide:nth-child(4) .scemeItem, .platform .splide__slide:nth-child(5) .scemeItem {
  height: auto;
}
@media (min-width: 1200px) {
  .platform .splide__slide:nth-child(3) .scemeItem, .platform .splide__slide:nth-child(4) .scemeItem, .platform .splide__slide:nth-child(5) .scemeItem {
    height: 10vw;
  }
}
@media (min-width: 1920px) {
  .platform .splide__slide:nth-child(3) .scemeItem, .platform .splide__slide:nth-child(4) .scemeItem, .platform .splide__slide:nth-child(5) .scemeItem {
    height: 20rem;
  }
}
.platform .splide__slide:nth-child(3) {
  padding-bottom: 2.1rem;
}
@media (min-width: 1200px) {
  .platform .splide__slide:nth-child(3) {
    padding-bottom: 1.2vw;
  }
}
@media (min-width: 1920px) {
  .platform .splide__slide:nth-child(3) {
    padding-bottom: 2.1rem;
  }
}
.platform .splide__slide:nth-child(3) .schemeContainer {
  max-width: 240px;
}
@media (min-width: 768px) {
  .platform .splide__slide:nth-child(3) .schemeContainer {
    max-width: 500px;
  }
}
.platform .splide__slide:nth-child(3) .schemeHeader {
  max-width: 262px;
}
@media (min-width: 768px) {
  .platform .splide__slide:nth-child(3) .schemeHeader {
    max-width: 500px;
  }
}
.platform .splide__slide:nth-child(3) .scemeItem {
  background-color: #ffffff;
  color: #000;
  font-weight: 500;
  padding: 2.5rem 2.5rem 5rem;
}
@media (min-width: 1200px) {
  .platform .splide__slide:nth-child(3) .scemeItem {
    padding: 1.2vw;
  }
}
@media (min-width: 1920px) {
  .platform .splide__slide:nth-child(3) .scemeItem {
    padding: 2.5rem;
  }
}
.platform .splide__slide:nth-child(3) .scemeItemText {
  font-size: 1.6rem;
}
@media (min-width: 1200px) {
  .platform .splide__slide:nth-child(3) .scemeItemText {
    font-size: 0.7vw;
  }
}
@media (min-width: 1920px) {
  .platform .splide__slide:nth-child(3) .scemeItemText {
    font-size: 1.3rem;
  }
}
.platform .splide__slide:nth-child(4) {
  padding-bottom: 2.1rem;
}
@media (min-width: 1200px) {
  .platform .splide__slide:nth-child(4) {
    padding-bottom: 1.2vw;
  }
}
@media (min-width: 1920px) {
  .platform .splide__slide:nth-child(4) {
    padding-bottom: 2.1rem;
  }
}
.platform .splide__slide:nth-child(4) .scemeItem {
  padding: 2.5rem 2.5rem 5rem;
}
@media (min-width: 1200px) {
  .platform .splide__slide:nth-child(4) .scemeItem {
    padding: 1.2vw;
  }
}
@media (min-width: 1920px) {
  .platform .splide__slide:nth-child(4) .scemeItem {
    padding: 2.5rem;
  }
}
.platform .splide__slide:nth-child(4) .schemeContainer {
  max-width: 240px;
}
@media (min-width: 768px) {
  .platform .splide__slide:nth-child(4) .schemeContainer {
    max-width: 500px;
  }
}
.platform .splide__slide:nth-child(4) .schemeHeader {
  max-width: 262px;
}
@media (min-width: 768px) {
  .platform .splide__slide:nth-child(4) .schemeHeader {
    max-width: 500px;
  }
}
.platform .splide__slide:nth-child(4) .scemeItemHeader {
  font-size: 3rem;
  margin-bottom: 1rem;
}
@media (min-width: 1200px) {
  .platform .splide__slide:nth-child(4) .scemeItemHeader {
    font-size: 1.1vw;
    margin-bottom: 0.4vw;
  }
}
@media (min-width: 1920px) {
  .platform .splide__slide:nth-child(4) .scemeItemHeader {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
}
.platform .splide__slide:nth-child(4) .scemeItemText {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
@media (min-width: 1200px) {
  .platform .splide__slide:nth-child(4) .scemeItemText {
    font-size: 0.7vw;
    margin-bottom: 0.4vw;
  }
}
@media (min-width: 1920px) {
  .platform .splide__slide:nth-child(4) .scemeItemText {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
}
.platform .splide__slide:nth-child(5) .schemeContainer, .platform .splide__slide:nth-child(5) .schemeHeader {
  max-width: 190px;
}
@media (min-width: 768px) {
  .platform .splide__slide:nth-child(5) .schemeContainer, .platform .splide__slide:nth-child(5) .schemeHeader {
    max-width: 500px;
  }
}
.platform .splide__slide:nth-child(5) .scemeItem {
  padding: 1rem;
}
@media (min-width: 1200px) {
  .platform .splide__slide:nth-child(5) .scemeItem {
    padding: 0.4vw;
  }
}
@media (min-width: 1920px) {
  .platform .splide__slide:nth-child(5) .scemeItem {
    padding: 0.8rem;
  }
}
.platform .scemeItem {
  border: 1px solid #fff;
  padding: 0.9rem 2.5rem;
  border-radius: 0.8rem;
  color: #fff;
}
@media (min-width: 1200px) {
  .platform .scemeItem {
    padding: 0.5vw 1.2vw;
  }
  .platform .scemeItem::after {
    content: "";
    position: absolute;
    right: -4.4rem;
    top: 50%;
    width: 3.6rem;
    height: 1.3rem;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='13' viewBox='0 0 48 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M47.0385 6.90766C47.3683 6.57788 47.3683 6.04321 47.0385 5.71343L41.6645 0.339422C41.3347 0.00964642 40.8001 0.00964642 40.4703 0.339422C40.1405 0.669199 40.1405 1.20387 40.4703 1.53365L45.2472 6.31055L40.4703 11.0874C40.1405 11.4172 40.1405 11.9519 40.4703 12.2817C40.8001 12.6114 41.3347 12.6114 41.6645 12.2817L47.0385 6.90766ZM0.441406 7.15499H46.4414V5.4661H0.441406V7.15499Z' fill='white'/%3E%3C/svg%3E%0A");
  }
}
@media (min-width: 1400px) {
  .platform .scemeItem::after {
    right: -4.6rem;
  }
}
@media (min-width: 1920px) {
  .platform .scemeItem {
    padding: 0.9rem 2.5rem;
  }
  .platform .scemeItem::after {
    right: -5.6rem;
    width: 4.6rem;
    height: 1.3rem;
  }
}
.platform .scemeItemHeader {
  line-height: 1;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 2.1rem;
  font-weight: 500;
}
@media (min-width: 1200px) {
  .platform .scemeItemHeader {
    font-size: 1.1vw;
  }
}
@media (min-width: 1920px) {
  .platform .scemeItemHeader {
    font-size: 2.5rem;
  }
}
.platform .scemeItemText {
  line-height: 1;
  margin-bottom: 0;
  font-size: 1.3rem;
}
@media (min-width: 1200px) {
  .platform .scemeItemText {
    font-size: 0.7vw;
  }
}
@media (min-width: 1920px) {
  .platform .scemeItemText {
    font-size: 1.3rem;
  }
}
.platform .scemeItemList {
  margin-bottom: 2rem;
}
@media (min-width: 1200px) {
  .platform .scemeItemList {
    margin-bottom: 1vw;
  }
}
@media (min-width: 1920px) {
  .platform .scemeItemList {
    margin-bottom: 2rem;
  }
}
.platform .scemeItemList li {
  padding-left: 1.5rem;
}
@media (min-width: 1200px) {
  .platform .scemeItemList li {
    padding-left: 0.8vw;
  }
}
@media (min-width: 1920px) {
  .platform .scemeItemList li {
    padding-left: 1.5rem;
  }
}
.platform .scemeItemList li:before {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 0.7rem;
  left: 0;
  top: 0.3rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.393343 4.34511L0.341517 4.40165C0.153607 4.60664 0.169503 4.92568 0.37686 5.11098L2.68871 7.17688C2.88272 7.35024 3.17737 7.34547 3.36565 7.1659L9.65972 1.16323C9.85083 0.980958 9.86685 0.681345 9.69626 0.479738L9.62963 0.400991C9.44511 0.182932 9.11556 0.163955 8.90723 0.359394L3.34877 5.57403C3.16727 5.7443 2.88807 5.75483 2.69427 5.59871L1.07428 4.2937C0.869267 4.12855 0.571231 4.15105 0.393343 4.34511Z' fill='white'/%3E%3C/svg%3E%0A");
}
@media (min-width: 1200px) {
  .platform .scemeItemList li:before {
    width: 0.6vw;
    height: 0.4vw;
    top: 0.2vw;
  }
}
@media (min-width: 1920px) {
  .platform .scemeItemList li:before {
    width: 1rem;
    height: 0.7rem;
    top: 0.3rem;
  }
}
.platform .scemeDisclamer {
  text-align: center;
  color: #fff;
  margin-top: 0.8rem;
}
@media (min-width: 1200px) {
  .platform .scemeDisclamer {
    margin-top: 0.5vw;
  }
}
@media (min-width: 1920px) {
  .platform .scemeDisclamer {
    margin-top: 0.8rem;
  }
}
.platform .dataBlocks {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.platform .dataBlocks span {
  width: 100%;
  display: block;
  height: 4.6rem;
  background-color: #3727F3;
  border-radius: 1rem;
  margin-bottom: 1rem;
}
@media (min-width: 1200px) {
  .platform .dataBlocks span {
    margin-bottom: 0;
    height: 2vw;
    border-radius: 0.5vw;
  }
}
@media (min-width: 1920px) {
  .platform .dataBlocks span {
    height: 3.8rem;
    border-radius: 1rem;
  }
}
.platform .splide__pagination__page {
  opacity: 1;
}

.services {
  padding: 5rem 0 5rem;
}
@media (min-width: 1920px) {
  .services {
    padding: 10rem 0 5rem;
  }
}
.services .servicesText {
  font-weight: 500;
  max-width: 670px;
  font-size: 1.8rem;
  margin-bottom: 3rem;
}
@media (min-width: 992px) {
  .services .servicesText {
    font-size: 2.4rem;
    margin-bottom: 6rem;
  }
}
.services h2 {
  line-height: 1;
  margin-bottom: 1.5rem;
}
@media (min-width: 992px) {
  .services h2 {
    margin-bottom: 2rem;
  }
}
.services h3 {
  color: #4625EB;
  font-size: 3.8rem;
  margin-bottom: 3rem;
}
@media (min-width: 992px) {
  .services h3 {
    font-size: 5.4rem;
    margin-bottom: 4rem;
  }
}
.services .gradientText {
  background: linear-gradient(180deg, #F70872 14.71%, #CB118F 41.62%, #A017AF 64.2%, #8E18BA 97.22%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.services .servicesList {
  margin-bottom: 3rem;
}
@media (min-width: 992px) {
  .services .servicesList {
    margin-bottom: 6rem;
    display: flex;
    justify-content: space-between;
  }
}
.services .servicesListItem {
  padding: 2rem;
  width: 100%;
  background: linear-gradient(135.94deg, #4E21CD 0%, #45A3F9 98.42%);
  color: #fff;
  margin-bottom: 3rem;
}
@media (min-width: 992px) {
  .services .servicesListItem {
    padding: 5rem 2.5rem 2.5rem;
    margin-bottom: 0;
    width: 31%;
  }
}
.services .servicesImg {
  margin-bottom: 1.5rem;
  width: 20rem;
  height: auto;
}
@media (min-width: 1200px) {
  .services .servicesImg {
    width: 25rem;
  }
}
.services .servicesItemText {
  font-size: 1.4rem;
  margin-bottom: 2rem;
}
@media (min-width: 1200px) {
  .services .servicesItemText {
    margin-bottom: 3rem;
    font-size: 2.2rem;
  }
}
.services .servicesItemDisclamer {
  font-size: 0.9rem;
}
@media (min-width: 1200px) {
  .services .servicesItemDisclamer {
    font-size: 1.2rem;
  }
}
.services .quoteBlockName {
  font-size: 1.4rem;
}
@media (min-width: 992px) {
  .services .quoteBlockName {
    font-size: 2rem;
  }
}
.services .quoteBlock {
  display: flex;
  flex-direction: column-reverse;
}
@media (min-width: 992px) {
  .services .quoteBlock {
    flex-direction: row;
  }
}
.services .quoteBlockImg {
  margin-bottom: 0.8rem;
  width: 8rem;
  height: 8rem;
}
@media (min-width: 992px) {
  .services .quoteBlockImg {
    margin-top: 1rem;
    width: 11.8rem;
    height: 11.8rem;
  }
}
.services .quoteBlockText {
  font-size: 1.4rem;
  max-width: 740px;
  z-index: 1;
  padding: 1rem 0.9rem 1.5rem;
}
.services .quoteBlockText::before, .services .quoteBlockText::after {
  content: open-quote;
  position: absolute;
  font-size: 15rem;
  line-height: 1;
  z-index: 0;
  color: rgba(70, 37, 235, 0.42);
  font-weight: bold;
}
@media (min-width: 992px) {
  .services .quoteBlockText::before, .services .quoteBlockText::after {
    font-size: 20rem;
  }
}
.services .quoteBlockText::before {
  content: open-quote;
  left: -0.9rem;
  top: -7rem;
}
@media (min-width: 992px) {
  .services .quoteBlockText::before {
    left: 0;
  }
}
.services .quoteBlockText::after {
  content: close-quote;
  right: -0.9rem;
  bottom: -3rem;
}
@media (min-width: 992px) {
  .services .quoteBlockText::after {
    right: 0;
    bottom: -2rem;
  }
}
@media (min-width: 992px) {
  .services .quoteBlockText {
    font-size: 2.4rem;
    padding: 3.4rem 2rem 3.4rem 8rem;
  }
}

.ac {
  counter-increment: item;
  border-top: 1px solid rgba(70, 37, 235, 0.3);
  box-sizing: border-box;
}
.ac:nth-child(2) .faqListItem {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .ac:nth-child(2) .faqListItem {
    margin-bottom: 0;
  }
}

.ac:last-child {
  border-bottom: 1px solid rgba(70, 37, 235, 0.3);
}

.ac .ac-header {
  margin: 0;
  padding: 0;
}

.ac .ac-trigger {
  font-family: "IBM Plex Mono", Arial, sans-serif;
  font-size: 1.8rem;
  color: #000;
  font-weight: 500;
  text-align: left;
  width: 100%;
  padding: 2rem 5.5rem 2rem 4rem;
  display: block;
  cursor: pointer;
  background-color: transparent;
  transition: color 0.25s ease;
  position: relative;
  text-decoration: none;
  margin: 0;
  border: 0;
}
@media (min-width: 992px) {
  .ac .ac-trigger {
    font-size: 3rem;
    padding: 2rem 2rem 2rem 20rem;
  }
}
.ac .ac-trigger:before {
  content: counter(item, decimal-leading-zero);
  color: #4625eb;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.ac .ac-trigger::after {
  content: "";
  text-align: center;
  width: 3.5rem;
  height: 3.5rem;
  transform: rotate(0deg) translateY(-50%);
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transition: all 0.4s ease-in-out;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M47.375 45.1278L47.375 1.86073L44.7528 1.86073L44.7528 43.3112L2.06656 0.624998L0.212635 2.47958L42.8608 45.1278L1.48573 45.1278L1.48573 47.75L44.7528 47.75L47.375 47.75L47.375 45.1278Z' fill='url(%23paint0_linear_1539_3164)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1539_3164' x1='43.3552' y1='11.9408' x2='3.3569' y2='38.5953' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23F70872'/%3E%3Cstop offset='0.229982' stop-color='%23CB118F'/%3E%3Cstop offset='0.401042' stop-color='%23A017AF'/%3E%3Cstop offset='0.578125' stop-color='%238E18BA'/%3E%3Cstop offset='0.733233' stop-color='%235C20DD'/%3E%3Cstop offset='1' stop-color='%233727F3'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
}
@media (min-width: 992px) {
  .ac .ac-trigger::after {
    width: 4.7rem;
    height: 4.7rem;
  }
}

.faqHiddenConteiner {
  padding: 0 2rem 2rem 4rem;
  max-width: 1120px;
}
@media (min-width: 992px) {
  .faqHiddenConteiner {
    padding: 0 2rem 2rem 20rem;
  }
}

.faqListItem {
  font-size: 1.6rem;
  padding-left: 22px;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .faqListItem {
    margin-bottom: 2rem;
    font-size: 2.4rem;
    padding-left: 27px;
  }
}
.faqListItem::before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.472012 5.21413L0.40982 5.28198C0.184328 5.52797 0.203404 5.91082 0.452232 6.13317L3.22645 8.61225C3.45926 8.82029 3.81284 8.81456 4.03878 8.59908L11.5917 1.39587C11.821 1.17715 11.8402 0.817614 11.6355 0.575685L11.5556 0.48119C11.3341 0.219518 10.9387 0.196746 10.6887 0.431272L4.01852 6.68884C3.80073 6.89316 3.46568 6.90579 3.23312 6.71845L1.28913 5.15244C1.04312 4.95426 0.685477 4.98127 0.472012 5.21413Z' fill='%234625EB'/%3E%3C/svg%3E%0A");
  width: 12px;
  height: 8px;
}
@media (min-width: 992px) {
  .faqListItem::before {
    width: 17px;
    height: 12px;
    top: 8px;
  }
}
.faqListItem strong {
  display: block;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .faqListItem strong {
    margin-bottom: 1rem;
  }
}

.ac .ac-panel {
  overflow: hidden;
  transition-property: height, visibility;
  transition-timing-function: ease;
}

.ac .ac-panel .ac-text {
  font: 15px/24px "Arial", sans-serif;
  color: #111;
  padding: 10px;
  margin: 0;
}

.ac.js-enabled .ac-panel {
  visibility: hidden;
}

.ac.is-active .ac-panel {
  visibility: visible;
}

.ac.is-active > .ac-header .ac-trigger::after {
  transform: rotate(-90deg) translateY(-50%);
  transform-origin: top center;
  transition: all 0.4s ease-in-out;
}

.faqListItemDisklamer {
  font-size: 1.2rem;
  padding-left: 22px;
}
@media (min-width: 992px) {
  .faqListItemDisklamer {
    font-size: 1.8rem;
    padding-left: 27px;
  }
}

.faq {
  padding: 5rem 0 10rem;
}
@media (min-width: 1920px) {
  .faq {
    padding: 10rem 0 15rem;
  }
}

.disscussForm {
  padding: 6rem 0 12rem;
  background: linear-gradient(100.84deg, #5120DB 0%, #42A3FC 100%);
}
@media (min-width: 1920px) {
  .disscussForm {
    padding: 9rem 0 10rem;
  }
}
.disscussForm .formWrapper {
  max-width: 1130px;
  margin: 0 auto;
}
.disscussForm h3 {
  color: #fff;
  font-family: "IBM Plex Mono", Arial, sans-serif;
  font-size: 3.2rem;
  margin-bottom: 2rem;
}
@media (min-width: 992px) {
  .disscussForm h3 {
    font-size: 4.5rem;
  }
}
@media (min-width: 1920px) {
  .disscussForm h3 {
    font-size: 5.5rem;
  }
}
.disscussForm .disscussTextWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
}
@media (min-width: 992px) {
  .disscussForm .disscussTextWrapper {
    align-items: flex-end;
    margin-bottom: 8rem;
  }
}
.disscussForm .disscussTextWrapper > * {
  color: #fff;
}
.disscussForm .disscussTextWrapper .disscussText {
  font-size: 1.8rem;
}
@media (min-width: 992px) {
  .disscussForm .disscussTextWrapper .disscussText {
    font-size: 2.4rem;
    padding-right: 2rem;
  }
}
.disscussForm .disscussTextWrapper a {
  font-weight: 500;
  font-size: 3.4rem;
  text-align: left;
}
@media (min-width: 992px) {
  .disscussForm .disscussTextWrapper a {
    font-size: 4rem;
  }
}
@media (min-width: 992px) {
  .disscussForm .disscussTextWrapper {
    flex-direction: row;
  }
}
.disscussForm .formGrid {
  margin-bottom: 1.2rem;
}
@media (min-width: 992px) {
  .disscussForm .formGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 1.2rem;
  }
}
@media (min-width: 992px) {
  .disscussForm .input-text:nth-child(1) {
    grid-row-start: 1;
    grid-column-start: 1;
  }
  .disscussForm .input-text:nth-child(2) {
    grid-row-start: 2;
    grid-column-start: 1;
  }
  .disscussForm .input-text:nth-child(3) {
    grid-row-start: 3;
    grid-column-start: 1;
  }
  .disscussForm .input-text:nth-child(4) {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 2;
  }
}
.disscussForm .input-text {
  transition: all 0.4s ease-in-out;
  border: 1px solid #fff;
  margin-bottom: 1.2rem;
}
@media (min-width: 992px) {
  .disscussForm .input-text {
    margin-bottom: 0;
  }
}
.disscussForm .input-text input, .disscussForm .input-text textarea {
  outline: none;
  font-size: 1.6rem;
  font-family: "IBM Plex Mono", Arial, sans-serif;
  display: block;
  height: 100%;
  width: 100%;
  background-color: transparent;
  border: none;
  color: #fff;
  padding: 2rem;
}
.disscussForm .input-text input::placeholder, .disscussForm .input-text textarea::placeholder {
  color: #fff;
}
.disscussForm .input-text textarea {
  resize: none;
  padding: 2rem 2rem 5rem;
}
@media (min-width: 992px) {
  .disscussForm .input-text textarea {
    padding: 2rem;
  }
}
.disscussForm .input-text.has-danger {
  border: 1px solid rgb(166, 15, 15);
}
.disscussForm .btn-form-send {
  background-color: #ffffff;
  color: #4625eb;
}
.disscussForm .pristine-error {
  font-size: 1.2rem;
  position: absolute;
  background-color: #fff;
  border-radius: 0.3rem;
  padding: 0.2rem 1.4rem;
  color: #4625eb;
  right: -0.6rem;
  top: -0.6rem;
}
.disscussForm .btn:disabled {
  color: #5C5C5C;
  pointer-events: none;
}
.disscussForm .formDisclamer {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 3rem;
}
@media (min-width: 992px) {
  .disscussForm .formDisclamer {
    max-width: 49%;
    margin-bottom: 4rem;
  }
}

#modal-success .success-header {
  text-align: center;
  font-weight: 700;
  margin-bottom: 2rem;
  font-family: "IBM Plex Mono", Arial, sans-serif;
  font-size: 2.2rem;
}
@media (min-width: 992px) {
  #modal-success .success-header {
    font-size: 3rem;
    margin-bottom: 2.8rem;
  }
}
#modal-success .success-text {
  font-family: "IBM Plex Mono", Arial, sans-serif;
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 7rem;
}
@media (min-width: 992px) {
  #modal-success .success-text {
    font-size: 1.8rem;
    margin-bottom: 4rem;
  }
}
#modal-success .btn {
  max-width: 427px;
  margin: 0 auto;
}
#modal-success .presonsBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
#modal-success .presonsBlockImg {
  margin: 0 auto 0.7rem;
  width: 8.2rem;
  height: 8.2rem;
  border-radius: 50%;
}
@media (min-width: 992px) {
  #modal-success .presonsBlockImg {
    width: 13rem;
    height: 13rem;
    margin: 0 auto 1.2rem;
  }
}
#modal-success .presonsBlockImg::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: -0.4rem;
  bottom: -0.4rem;
  background-color: #4625EB;
  border-radius: 50%;
}
#modal-success .presoneName {
  text-align: center;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 700;
}
@media (min-width: 992px) {
  #modal-success .presoneName {
    font-size: 2.2rem;
  }
}
#modal-success .presonePosition, #modal-success .presoneSocial {
  text-align: center;
  font-size: 1rem;
}
@media (min-width: 992px) {
  #modal-success .presonePosition, #modal-success .presoneSocial {
    font-size: 1.4rem;
  }
}
#modal-success .presone {
  padding: 0 0.5rem;
  margin-bottom: 9rem;
}
@media (min-width: 768px) {
  #modal-success .presone {
    margin-bottom: 5rem;
  }
}
#modal-success .presoneSocial {
  text-decoration: underline;
  display: block;
}

.footer-shadow {
  color: #fff;
  position: absolute;
  bottom: 1.6rem;
  font-size: 1.2rem;
  width: 100%;
}
.footer-shadow a {
  font-size: 1.2rem;
  margin-bottom: 1.8rem;
  display: inline-block;
  color: #fff;
}
@media (min-width: 992px) {
  .footer-shadow a {
    margin-bottom: 0;
  }
}
.footer-shadow p {
  font-size: 1.8rem;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .footer-shadow p {
    font-size: 1.2rem;
  }
}
.footer-shadow .wrapper {
  text-align: center;
}
@media (min-width: 992px) {
  .footer-shadow .wrapper {
    display: flex;
    justify-content: space-between;
  }
}

.politic {
  padding: 13rem 0 10rem;
  position: relative;
  overflow: hidden;
}
@media (min-width: 992px) {
  .politic {
    padding: 23rem 0 15rem;
  }
}
.politic:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  min-width: 1000px;
  max-width: 2088px;
  height: 100%;
  max-height: 500px;
  top: 0%;
  left: -200px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(244, 7, 112, 0.6) 0%, rgba(107, 20, 167, 0.175) 60.42%, rgba(57, 10, 221, 0) 100%);
  filter: blur(100px);
  transform: rotate(-13.17deg);
}
@media (min-width: 768px) {
  .politic:before {
    left: 0;
  }
}
@media (min-width: 992px) {
  .politic:before {
    max-height: 600px;
    left: 10%;
    top: 12rem;
  }
}
.politic h1 {
  font-size: 2.7rem;
}
@media (min-width: 992px) {
  .politic h1 {
    font-size: 4rem;
  }
}
.politic h2 {
  margin: 0 0 2rem;
  font-family: "Bebas Neue", Arial, sans-serif;
  font-weight: bold;
  color: #000000;
  font-size: 2.2rem;
}
@media (min-width: 992px) {
  .politic h2 {
    margin: 0 0 4rem;
    font-size: 2.7rem;
  }
}
.politic p {
  margin: 0 0 1rem;
  font-size: 1.4rem;
}
@media (min-width: 992px) {
  .politic p {
    font-size: 1.7rem;
    margin: 0 0 2rem;
  }
}
.politic p.strong, .politic p.submenu {
  padding-left: 2rem;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  -ms-flex-align: center;
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 2em;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: 0.9;
}

.splide__arrow:disabled {
  opacity: 0.3;
}

.splide__arrow:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 8px;
}

.splide__pagination__page.is-active {
  background: #fff;
  transform: scale(1.4);
  z-index: 1;
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}

.splide__pagination__page:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__pagination__page:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #0bf;
  }
}
@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #0bf;
  }
  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}
.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__toggle:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__track--nav > .splide__list > .splide__slide {
  border: 3px solid transparent;
  cursor: pointer;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  bottom: 1em;
  top: auto;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide__pagination--ttb {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 0.5em;
  top: 0;
}

.modal__overlay {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
}

.modal__container {
  background-color: #ffffff;
  padding: 4rem 2rem;
  max-width: 740px;
  max-height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
}
@media (min-width: 992px) {
  .modal__container {
    padding: 6rem;
  }
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.modal__close {
  background: transparent;
  border: 0;
}

.modal__header .modal__close:before {
  content: "✕";
}

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
}

.modal__btn {
  font-size: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.modal__btn:focus, .modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden=false] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=false] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=true] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=true] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}